import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { SetEmailPhoneDetails, SetEmailPhoneValue } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  setEmailPhoneRes: SetEmailPhoneValue;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  setEmailPhoneRes: {} as SetEmailPhoneValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const setEmailOrPhone = createAsyncThunk<
  SetEmailPhoneValue,
  SetEmailPhoneDetails
>(
  "setEmailOrPhone/setEmailOrPhone",
  async (payload: SetEmailPhoneDetails, { rejectWithValue }) => {
    return await axios
      .post(
        Url.apiURL + "/profileapp/api/userDetails/set_email_or_phone",
        payload,
        { headers }
      )
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", response.data.user.email);
        localStorage.setItem("firstname", response.data.user.first_name);
        localStorage.setItem("lastname", response.data.user.last_name);
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const setEmailOrPhoneSlice = createSlice({
  name: "setEmailOrPhone",
  initialState,
  reducers: {
    updateSetEmailOrPhoneResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearSetEmailOrPhoneAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.setEmailPhoneRes = {} as SetEmailPhoneValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEmailOrPhone.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      setEmailOrPhone.fulfilled,
      (state, action: PayloadAction<SetEmailPhoneValue>) => {
        state.loading = false;
        state.setEmailPhoneRes = action.payload;
        state.successMsg = "JWT Authentication Successful";
        state.errorMsg = "";
      }
    );
    builder.addCase(setEmailOrPhone.rejected, (state, action) => {
      state.loading = false;
      state.setEmailPhoneRes = {} as SetEmailPhoneValue;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateSetEmailOrPhoneResMsg, clearSetEmailOrPhoneAPIRes } =
  setEmailOrPhoneSlice.actions;
export default setEmailOrPhoneSlice.reducer;
