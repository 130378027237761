import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { t } from "i18next";
import { resetPassword } from "../../redux/resetPassword/resetPasswordSlice";
import { ResetPasswordDetails } from "../../redux/resetPassword/Model";
import { logOut } from "../../redux/logOut/logOutSlice";
import { LogOutBody } from "../../redux/logOut/Model";
import { useAppSelector } from "../../redux/hooks";
import { AppDispatch } from "../../redux/store";
interface Props {
  toggleResetPwd: () => void;
  isResetPwdOpen: boolean;
}
const ResetPwd = ({ toggleResetPwd, isResetPwdOpen }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  interface PwdState {
    password: string;
    showPassword: boolean;
    status?: string;
    msg?: string;
  }
  const [oldPassword, setOldPassword] = React.useState<PwdState>({
    password: "",
    showPassword: false,
  });
  const handleOldPasswordChange =
    (prop: keyof PwdState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setOldPassword({
        ...oldPassword,
        [prop]: event.target.value,
        status: "",
      });
    };

  const handleOldPasswordClickShowPassword = () => {
    setOldPassword({ ...oldPassword, showPassword: !oldPassword.showPassword });
  };
  const handleOldMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [newPassword, setNewPassword] = React.useState<PwdState>({
    password: "",
    showPassword: false,
  });

  const handleNewPasswordChange =
    (prop: keyof PwdState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword({
        ...newPassword,
        [prop]: event.target.value,
        status: "",
      });
    };

  const handleNewPasswordClickShowPassword = () => {
    setNewPassword({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };

  const handleNewPasswordMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // Reset Password Modal
  const [resetPasswordResetSuccess, setResetPasswordResetSuccess] =
    useState("");

  const [resetPasswordResetError, setResetPasswordResetError] = useState("");

  const resetPasswordValue = useAppSelector((state) => state.resetPassword);
  // Reset Password Body
  const resetbody = {
    old_password: oldPassword.password,
    new_password: newPassword.password,
  } as ResetPasswordDetails;

  // Reset Password API
  const resetgetPasswordApicall = () => {
    if (oldPassword.password === "") {
      setOldPassword({
        ...oldPassword,
        msg: `Please enter old password`, //`${t("userprofile.enteroldpsw", { ns: ["home"] })!}`,
        status: "Error",
      });
    } else if (newPassword.password === "") {
      setNewPassword({
        ...newPassword,
        msg: `Please enter new password`, //`${t("userprofile.newpsw", { ns: ["home"] })!}`,
        status: "Error",
      });
    } else {
      setNewPassword({
        ...newPassword,
        status: "Success",
      });
      setOldPassword({
        ...oldPassword,
        status: "Success",
      });
      dispatch(resetPassword(resetbody));
    }
  };

  const Token = localStorage.getItem("access");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logOutBody = {
    access: Token!,
  } as LogOutBody;

  useEffect(() => {
    if (resetPasswordValue?.resetPasswordRes.message !== undefined) {
      setResetPasswordResetError(resetPasswordValue?.resetPasswordRes.message);
      setResetPasswordResetSuccess("");
    } else if (resetPasswordValue?.errorMsg === "Email Failure") {
      setResetPasswordResetSuccess(resetPasswordValue.errorMsg);
      setResetPasswordResetError("");
      dispatch(logOut(logOutBody));
    } else {
      setResetPasswordResetSuccess(resetPasswordValue?.errorMsg);
      setResetPasswordResetError("");
    }
  }, [resetPasswordValue, logOutBody, dispatch]);

  return (
    <Dialog
      open={isResetPwdOpen}
      onClose={toggleResetPwd}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Reset Password
        {/* {t("userprofile.resetpsword", { ns: ["home"] })!} */}
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ my: 2 }} fullWidth variant="outlined">
          <InputLabel size="small" htmlFor="outlined-old-password">
            Please enter old password
            {/* {t("userprofile.enteroldpsw", { ns: ["home"] })!} */}
          </InputLabel>
          <OutlinedInput
            id="outlined-old-password"
            size="small"
            error={oldPassword.status === "Error" ? true : false}
            placeholder={
              `Please enter old password`
              // t("userprofile.enteroldpsw", { ns: ["home"] })!
            }
            label={
              `Please enter old password`
              //t("userprofile.enteroldpsw", { ns: ["home"] })!
            }
            type={oldPassword.showPassword ? "text" : "password"}
            value={oldPassword.password}
            onChange={handleOldPasswordChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleOldPasswordClickShowPassword}
                  onMouseDown={handleOldMouseDownPassword}
                  edge="end"
                  size="small"
                >
                  {oldPassword.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            <Typography
              component={"span"}
              color={oldPassword.status === "Error" ? "error" : "success"}
            >
              {oldPassword.msg}
            </Typography>
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <InputLabel size="small" htmlFor="outlined-new-password">
            Enter your password
            {/* {t("loginpage.enterpassword", { ns: ["home"] })!} */}
          </InputLabel>
          <OutlinedInput
            id="outlined-new-password"
            size="small"
            error={newPassword.status === "Error" ? true : false}
            placeholder={
              "Enter your password"
              // t("loginpage.enterpassword", { ns: ["home"] })!
            }
            type={newPassword.showPassword ? "text" : "password"}
            value={newPassword.password}
            onChange={handleNewPasswordChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleNewPasswordClickShowPassword}
                  onMouseDown={handleNewPasswordMouseDownPassword}
                  edge="end"
                  size="small"
                >
                  {newPassword.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            <Typography
              component={"span"}
              color={newPassword.status === "Error" ? "error" : "success"}
            >
              {newPassword.msg}
            </Typography>
          </FormHelperText>
        </FormControl>
        <Typography component={"span"} color="success">
          {resetPasswordResetSuccess}
        </Typography>
        <Typography component={"span"} color="error">
          {resetPasswordResetError}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleResetPwd}>Cancel</Button>
        <Button variant="contained" onClick={resetgetPasswordApicall}>
          Reset
          {/* {t("common.reset", { ns: ["home"] })!} */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPwd;
