import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Snackbar,
  Button,
  Container,
  Link,
  Typography,
  Box,
  Alert,
  IconButton,
  OutlinedInput,
  InputAdornment,
  TextField,
} from "@mui/material";
import { emailOtpGeneration } from "../../redux/emailOtpGeneration/emailOtpGenerationSlice";
import { emailOtpGenerationDetails } from "../../redux/emailOtpGeneration/Model";
import { useDispatch } from "react-redux";
import { emailOtpSignupDetails } from "../../redux/emailOtpSignup/Model";
import { emailOtpSignup } from "../../redux/emailOtpSignup/emailOtpSignupSlice";
import OnboardingLayout from "./OnboardingLayout";
import MWLoader from "../../components/MWLoader";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";

export default function Signup() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  // const [password, setPass] = useState('');
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  // const { t } = useTranslation(["home"]);

  const [error, setError] = useState("");
  const [errormail, setErrorMail] = useState("");
  const [errorotp, setErrorOtp] = useState("");
  const [errorFstName, setErrorFstName] = useState("");
  const [errorLstName, setErrorLstName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const errorEmail = errormail ? <Typography>{errormail}</Typography> : "";

  const errorOtp = errorotp ? <Typography>{errorotp}</Typography> : "";
  const errorFirstName = errorFstName ? (
    <Typography>{errorFstName}</Typography>
  ) : (
    ""
  );
  const errorLastName = errorLstName ? (
    <Typography>{errorLstName}</Typography>
  ) : (
    ""
  );

  const BannerClose = () => {
    setError("");
  };
  const errorDiv = error ? (
    <Alert variant="standard" severity="error" onClose={() => BannerClose()}>
      {error}
    </Alert>
  ) : (
    ""
  );

  const sectionForm = isLoading ? <MWLoader /> : null;

  const emailOtpGen = useAppSelector((state) => state.emailOtpGeneration);
  const emailOtpSignupRes = useAppSelector((state) => state.emailOtpSignup);
  const [isDisableOTPField, setIsDisableOTPField] = useState(true);
  const [isDisableFastnameField, setIsDisableFastnameField] = useState(true);
  const [isDisableLastnameField, setIsDisableLastnameField] = useState(true);
  const [isDisablePassword, setIsDisablePassword] = useState(true);
  const [buttonText, setButtonText] = useState("Generate OTP");
  const [signsuccessMessage, setsignsuccessMessage] = useState("");
  const [generateOtpSuccessMessage, setGenerateOtpSuccessMessage] =
    useState("");
  const [generateOtpErrorMessage, setGenerateOtpErrorMessage] = useState("");
  const closeSuccess = () => {
    setsignsuccessMessage("");
    navigate("/signin");
  };

  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPass] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPass({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPass({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const closeSuccessBanner = () => {
    setGenerateOtpSuccessMessage("");
  };

  const successDiv = signsuccessMessage ? (
    <Snackbar
      autoHideDuration={6000}
      message={signsuccessMessage}
      onClose={closeSuccess}
    />
  ) : null;

  const otpsuccessDiv = generateOtpSuccessMessage ? (
    <Alert
      variant="standard"
      severity="success"
      onClose={() => closeSuccessBanner()}
    >
      <p>{generateOtpSuccessMessage}</p>
    </Alert>
  ) : (
    ""
  );
  const closeOtpErrorBanner = () => {
    setGenerateOtpErrorMessage("");
  };

  const otpErrorDiv = generateOtpErrorMessage ? (
    <Alert
      variant="standard"
      severity="error"
      onClose={() => closeOtpErrorBanner()}
    >
      <p>{generateOtpErrorMessage}</p>
    </Alert>
  ) : (
    ""
  );

  // ******* generate otp section modal **********
  const otpText = buttonText;
  const generateOtpBody = {
    email: email.toLowerCase(),
  } as emailOtpGenerationDetails;

  const generateOtpApicall = () => {
    if (email === "") {
      setErrorMail("Email id or phone number should not be empty");
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setErrorMail("Please enter valid email id or phone number");
    } else {
      setErrorMail("");
      dispatch(emailOtpGeneration(generateOtpBody));
    }
  };

  useEffect(() => {
    if (emailOtpGen?.emailOtpGenerationRes?.message !== undefined) {
      setGenerateOtpSuccessMessage(emailOtpGen?.emailOtpGenerationRes?.message);
      setIsDisableOTPField(false);
      setIsDisableFastnameField(false);
      setIsDisableLastnameField(false);
      setIsDisablePassword(false);
      setButtonText("Resend OTP");
      setGenerateOtpErrorMessage("");
    } else {
      setGenerateOtpSuccessMessage("");
      setGenerateOtpErrorMessage(emailOtpGen?.emailOtpGenerationRes?.message);
      setButtonText("Send OTP");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpGen]);

  useEffect(() => {
    emailOtpGen?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [emailOtpGen]);

  const signupBody = {
    email: email.toLowerCase(),
    password: String(password.password),
    first_name: firstname,
    last_name: lastname,
    otp: otp,
  } as emailOtpSignupDetails;

  const handleSignup = () => {
    if (email === "") {
      setErrorMail("Email id or phone number should not be empty");
      setErrorOtp("");
      setErrorFstName("");
      setErrorLstName("");
    } else if (otp === "") {
      setErrorOtp("Please enter valid OTP");
      setErrorFstName("Enter First Name");
      setErrorLstName("Enter Last Name");
    } else if (firstname === "") {
      setErrorOtp("");
      setErrorFstName("Enter First Name");
      setErrorLstName("Enter Last Name");
    } else if (lastname === "") {
      setErrorOtp("");
      setErrorFstName("");
      setErrorLstName("Enter Last Name");
    } else {
      dispatch(emailOtpSignup(signupBody));
    }
  };

  useEffect(() => {
    if (emailOtpSignupRes?.emailOtpSignupRes.message !== undefined) {
      setsignsuccessMessage(emailOtpSignupRes?.emailOtpSignupRes.message);
      setGenerateOtpErrorMessage("");
      setGenerateOtpSuccessMessage("");
    } else {
      setGenerateOtpErrorMessage("");
      setGenerateOtpSuccessMessage("");
      setsignsuccessMessage("");
      setError(emailOtpSignupRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpSignupRes]);

  useEffect(() => {
    emailOtpSignupRes?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [emailOtpSignupRes]);

  const actualPageMarkup = (
    <Box>
      <TextField
        fullWidth
        sx={{ my: 2 }}
        size="small"
        value={email}
        error={errorEmail === "" ? false : true}
        label={"Email"}
        placeholder={"We would like to verify your email first"}
        id="emailFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
          setErrorMail("");
        }}
        helperText={
          errorEmail === ""
            ? "Please provide email or phone number"
            : errorEmail
        }
      />
      <div>{otpsuccessDiv}</div>
      <div>{otpErrorDiv}</div>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={generateOtpApicall}
      >
        {otpText}
      </Button>
      <TextField
        fullWidth
        sx={{ my: 2 }}
        value={otp}
        error={errorOtp === "" ? false : true}
        label={"OTP"}
        placeholder={"Enter OTP"}
        id="OTPFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setOtp(event.target.value);
        }}
        disabled={isDisableOTPField}
        size="small"
        helperText={errorOtp === "" ? "Please provide OTP" : errorOtp}
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            value={firstname}
            label={"First Name"}
            placeholder={"Enter First Name"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFirstname(event.target.value);
            }}
            disabled={isDisableFastnameField}
            id="firstnameID"
            error={errorFirstName === "" ? false : true}
            size="small"
            helperText={
              errorFirstName === ""
                ? "Please provide first name"
                : errorFirstName
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={lastname}
            label={"Last Name"}
            placeholder={"Enter Last Name"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLastname(event.target.value);
            }}
            error={errorLastName === "" ? false : true}
            size="small"
            helperText={
              errorLastName === "" ? "Please provide last name" : errorLastName
            }
            disabled={isDisableLastnameField}
            id="lastnameID"
          />
        </Grid>
      </Grid>
      <FormControl sx={{ my: 2 }} fullWidth variant="outlined">
        <InputLabel size="small" htmlFor="outlined-adornment-password">
          Enter your password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          size="small"
          placeholder={"Password (Optional)"}
          label={"Password (Optional)"}
          type={password.showPassword ? "text" : "Password"}
          value={password.password}
          onChange={handleChange("password")}
          disabled={isDisablePassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                disabled={isDisablePassword}
                edge="end"
                size="small"
              >
                {password.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>{"Please enter password"}</FormHelperText>
      </FormControl>
      <Typography variant="caption">
        {"By clicking Register, you agree to our"}&nbsp;
        <Link href="https://web.cloud-doctor.de/terms-of-use.html">
          {"Terms of Use"}
        </Link>
        &nbsp;and&nbsp;
        <Link href="https://web.cloud-doctor.de/privacy-statement.html">
          {"Privacy & Cookies"}.
        </Link>
      </Typography>
      <Box>
        <Button
          fullWidth
          onClick={handleSignup}
          disabled={isDisableLastnameField}
          variant="contained"
        >
          Register
        </Button>
      </Box>
      <hr />
      <Typography>
        {"Old User?"} &nbsp;&nbsp;
        <Button variant="outlined" href="/signin">
          Log In
        </Button>
      </Typography>
      <div>{errorDiv}</div>
    </Box>
  );
  return (
    <Container maxWidth="xl">
      <OnboardingLayout>{actualPageMarkup}</OnboardingLayout>
      {sectionForm}
      {successDiv}
    </Container>
  );
}
