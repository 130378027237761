import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Url from "../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { ImageUploadResponse } from "./Model";
import { headers } from "../../components/Utility";

const updatedHeaders = { ...headers, "Content-type": "multipart/form-data" };

// export const imageUploadApi = createAsyncThunk(
//   "imageUpload/upload",
//   async (formData, { rejectWithValue }) => {
//     try {
//       const response = await setupInterceptorsTo(axios).post(
//         `${Url.apiURL}/storageapp/api/upload-image/`,
//         formData,
//         {
//           headers: updatedHeaders,
//           onUploadProgress: (progress) => {
//             const percentageProgress = Math.floor(
//               (progress.loaded / progress?.total!) * 100
//             );
//             return percentageProgress;
//           },
//           transformRequest: (formData) => formData,
//         }
//       );
//       return response.data;
//     } catch (error: any) {
//       return rejectWithValue(
//         error.response?.data?.message || "Could not upload the Image!"
//       );
//     }
//   }
// );

const initialState = {
  loading: false,
  imageUploadResponse: {} as ImageUploadResponse,
  progress: 0,
  successMsg: "",
  errorMessage: "",
  status: "0",
};

export const imageUploadApi = createAsyncThunk<
  ImageUploadResponse, // Return type of fulfilled promise
  FormData, // Argument type
  { rejectValue: string } // Rejected value type
>("imageUpload/upload", async (formData, { rejectWithValue }) => {
  try {
    const response = await setupInterceptorsTo(axios).post(
      `${Url.apiURL}/storageapp/api/upload-image/`,
      formData,
      {
        headers: updatedHeaders,
        onUploadProgress: (progress) => {
          const percentageProgress = Math.floor(
            (progress.loaded / (progress?.total ?? 1)) * 100
          );
          return percentageProgress;
        },
        transformRequest: (formData) => formData,
      }
    );
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Could not upload the Image!"
    );
  }
});

const imageUploadSlice = createSlice({
  name: "imageUpload",
  initialState,
  reducers: {
    clearImageUploadAPIRes: (state) => {
      state.imageUploadResponse = {} as ImageUploadResponse;
      state.successMsg = "";
      state.errorMessage = "";
      state.status = "0";
    },
    updateImageUploadAPIResMsg: (state) => {
      state.successMsg = "";
      state.errorMessage = "";
      state.status = "0";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(imageUploadApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(imageUploadApi.fulfilled, (state, action) => {
        state.loading = false;
        state.imageUploadResponse = action.payload;
        state.successMsg = action.payload.message;
        state.status = String(action.payload.status);
      })
      .addCase(imageUploadApi.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = String(action.payload)!;
      });
  },
});

export const { clearImageUploadAPIRes, updateImageUploadAPIResMsg } =
  imageUploadSlice.actions;
export default imageUploadSlice.reducer;
