import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import jwtPatientLogInSlice from "./jwtPatientLogIn/jwtAuthenticationSlice";
import emailOtpGenerationSlice from "./emailOtpGeneration/emailOtpGenerationSlice";
import emailOtpSignupSlice from "./emailOtpSignup/emailOtpSignupSlice";
import emailOtpAuthSlice from "./emailOtpAuth/emailOtpAuthSlice";
import forgotPasswordSlice from "./forgotPassword/forgotPasswordSlice";
import logOutSlice from "./logOut/logOutSlice";
import imageUploadSlice from "./ImageUpload/imageUploadSlice";
import addNeedHelpSlice from "./needHelp/addNeedHelp/addNeedHelpSlice";
import patientListSlice from "./needHelp/patientList/patientListSlice";
import getUserDetailsSlice from "./getUserDetails/getUserDetailsSlice";
import resetPasswordSlice from "./resetPassword/resetPasswordSlice";
import setEmailOrPhoneSlice from "./setEmailOrPhone/setEmailOrPhoneSlice";
import patientLoginDetailsSlice from "./jwtPatientLogIn/patientLoginDetails/patientLoginDetailsSlice";
import contestListSlice from "./Dashboard/ContestList/contestListSlice";
import patientDetailsListOfPatientByCompetitionIdSlice from "./CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/patientDetailsListOfPatientByCompetitionIdSlice";
import unlinkedUploadedImageListByUserSlice from "./CompetitionDetails/UnlinkedUploadedImageListByUser/unlinkedUploadedImageListByUserSlice";
import addPatientDetailsSlice from "./addPatientDetails/addPatientDetailsSlice";
import addPatientDetailsListInCompetitionIdSlice from "./AddPatientDetailsListInCompetitionId/addPatientDetailsListInCompetitionIdSlice";
import userListSlice from "./Dashboard/UserList/userListSlice";
import appLoaderSlice from "./appLoader/appLoaderSlice";
const store = configureStore({
  reducer: {
    emailOtpGeneration: emailOtpGenerationSlice,
    emailOtpSignup: emailOtpSignupSlice,
    emailOtpAuth: emailOtpAuthSlice,
    apiAuthToken: jwtPatientLogInSlice,
    forgotPassword: forgotPasswordSlice,
    logOutRes: logOutSlice,
    imageUploading: imageUploadSlice,
    addNeedHelp: addNeedHelpSlice,
    dropdown: patientListSlice,
    getUserDetailsList: getUserDetailsSlice,
    resetPassword: resetPasswordSlice,
    setEmailPhone: setEmailOrPhoneSlice,
    getPatientLoginDetailsRes: patientLoginDetailsSlice,
    contestListRes: contestListSlice,
    getPatientDetailsByCompetitionRes:
      patientDetailsListOfPatientByCompetitionIdSlice,
    getUnLinkedImageListByUserRes: unlinkedUploadedImageListByUserSlice,
    addPointDataDetailsRes: addPatientDetailsSlice,
    addImageToCompetitionRes: addPatientDetailsListInCompetitionIdSlice,
    getUserListRes: userListSlice,
    appLoaderRes: appLoaderSlice,
  },
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, alerts: AlertsState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
