import {
  Button,
  Dialog,
  IconButton,
  Slide,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Box,
  Alert,
  Typography,
  AlertColor,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MWUploader from "../../../components/MWUploader"; //"components/MWUploader";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { clearImageUploadAPIRes } from "../../../redux/ImageUpload/imageUploadSlice"; //"redux/ImageUpload/API";
import {
  addPatientDetails,
  updateAddPatientDetailsResMsg,
  clearAddPatientDetailsAPIRes,
} from "../../../redux/addPatientDetails/addPatientDetailsSlice"; //"redux/addPatientDetails/API";
import {
  clearPatientDataPointAPIRes,
  updatePatientDataPointResMsg,
} from "../../../redux/UpdatePatientDataPoint/updatePatientDataPointSlice"; //"redux/UpdatePatientDataPoint/API";
// import { AppState } from "../../../redux/"; //"redux/store/Store";
import dayjs from "dayjs";
import { AddPatientDataDetailsBody } from "../../../redux/addPatientDetails/Model";
import MWSnackbar from "../../../components/MWSnackbar"; //"components/MWSnackbar";`
import { getUnLinkedImageListByUser } from "../../../redux/CompetitionDetails/UnlinkedUploadedImageListByUser/unlinkedUploadedImageListByUserSlice";
import { GetUnLinkedImageListByUserBody } from "../../../redux/CompetitionDetails/UnlinkedUploadedImageListByUser/Model";
import { useAppSelector } from "../../../redux/hooks";
import { AppDispatch } from "../../../redux/store";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  enable: boolean;
  dialogUpdateState: boolean | any;
  itemId?: any;
}
export default function AddImage({ enable, dialogUpdateState }: Props) {
  const userId = localStorage.getItem("defaultUserId");
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const { competitionpageno } = useParams() as { competitionpageno: string };
  useEffect(() => {
    setOpen(enable);
  }, [enable]);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const [imageId, setImageId] = React.useState("");

  const [dataPoint, setDataPoint] = React.useState("");

  const [selectedDate, setSelectedDate] = React.useState<Date | any>(
    new Date()
  );
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleDialogClose = () => {
    setOpen(false);
    dialogUpdateState(false);
    dialogUpdateState(false);
    setDataPoint("");
    setSelectedDate(new Date());
    dispatch(clearAddPatientDetailsAPIRes());
    dispatch(clearPatientDataPointAPIRes());
    dispatch(clearImageUploadAPIRes());
  };

  const updateDataStateFromChild = async (passedval: any) => {
    setImageId(passedval);
  };

  const closeSummaryBanner = () => {
    dispatch(updateAddPatientDetailsResMsg());
    dispatch(updatePatientDataPointResMsg());
  };

  const imageUploadRes = useAppSelector((state) => state.imageUploading);

  const dataPointBody = {
    patientId: String(userId!),
    patientDocumentId: imageId!,
    startDate: dayjs(String(selectedDate!)).format("YYYY-MM-DDTHH:mm:ss"),
    description: dataPoint!,
  } as AddPatientDataDetailsBody;

  const submitPatientData = () => {
    dispatch(addPatientDetails(dataPointBody));
  };
  const delImgAction = (passeVal: boolean) => {
    if (passeVal) {
      dispatch(clearImageUploadAPIRes());
    }
  };
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    setCheckImgUpload(passedVal);
  };

  const [updateDataPointSuccess, setUpdateDataPointSuccess] =
    React.useState("");
  const [alertType, setAlertType] = useState<AlertColor>("success");
  const [updateDataPointError, setUpdateDataPointError] = useState("");
  const updateDataPointSuccessDiv = updateDataPointError ? (
    <Alert
      variant="outlined"
      severity={alertType}
      onClose={() => {
        closeSummaryBanner();
      }}
    >
      <Typography>{updateDataPointError}</Typography>
    </Alert>
  ) : null;

  const addDataDetailsStoreVal = useAppSelector(
    (state) => state.addPointDataDetailsRes
  );

  React.useEffect(() => {
    if (
      addDataDetailsStoreVal?.addPatientDataDetailsRes.message !== undefined
    ) {
      setUpdateDataPointSuccess(
        addDataDetailsStoreVal?.addPatientDataDetailsRes.message
      );
      handleDialogClose();
      dispatch(
        getUnLinkedImageListByUser({
          competitionId: competitionid,
          patientId: String(userId!),
          pageNo: Number(competitionpageno),
        } as GetUnLinkedImageListByUserBody)
      );
    }
    if (addDataDetailsStoreVal?.errorMsg !== "") {
      setAlertType("error");
      setUpdateDataPointError(addDataDetailsStoreVal?.errorMsg);
    }
    if (addDataDetailsStoreVal?.successMsg !== "") {
      setAlertType("success");
      setUpdateDataPointSuccess(addDataDetailsStoreVal?.successMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDataDetailsStoreVal]);
  // const updateDataDetailsVal = useAppSelector((state) => state.updatePatientDataDetailsRes);
  // React.useEffect(() => {
  //   if (updateDataDetailsVal?.updatePatientDataDetailsRes?.message !== undefined) {
  //     setUpdateDataPointSuccess(updateDataDetailsVal?.updatePatientDataDetailsRes?.message);
  //     handleDialogClose();
  //     dispatch(getUnLinkedImageListByUser(patientid, page));
  //     if (selectedItem !== undefined) {
  //       dispatch(getPatientDataDetailsById(selectedItem));
  //     }
  //   }
  //   if (updateDataDetailsVal?.message !== "") {
  //     setAlertType("error");
  //     setUpdateDataPointError(updateDataDetailsVal?.message);
  //   } else {
  //     setAlertType("success");
  //     setUpdateDataPointSuccess(addDataDetailsStoreVal?.message);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateDataDetailsVal]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAddPatientDetailsAPIRes());
      // dispatch(clearPatientDataPointAPIRes());
    }
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Grid>Add Image</Grid>
            <Grid>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
            spacing={2}
          >
            <Grid size={{ xs: 12 }}>
              <TextField
                fullWidth
                autoFocus
                id="data"
                label="Data"
                variant="outlined"
                value={dataPoint}
                onChange={(e) => {
                  setDataPoint(e.target.value);
                }}
                inputProps={{ style: { fontSize: 15 } }}
                InputLabelProps={{ style: { fontSize: 15 } }}
                size="small"
                multiline
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Controlled picker"
                  value={selectedDate}
                  onChange={(newValue: any) => handleDateChange(newValue)}
                  disablePast
                  views={["year", "month", "day"]}
                />
              </LocalizationProvider>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <MWUploader
                documentType="patient-image"
                criteria="Upload a file less than 25MB."
                updatedDataState={updateDataStateFromChild}
                title={"Upload Your Image"}
                // imageUrl={value?.patientDocument?.document}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
          </Grid>
          <Box>{updateDataPointSuccessDiv}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={submitPatientData}
            variant="contained"
            disabled={
              imageUploadRes?.loading ||
              //   addDataDetailsStoreVal?.loading ||
              //   updateDataDetailsVal?.loading ||
              checkImgUpload
            }
          >
            Save
            {/* {value?.id !== undefined ? "Update" : "Save"} */}
          </Button>
        </DialogActions>
      </Dialog>
      <MWSnackbar
        msg={updateDataPointSuccess}
        type={alertType}
        alertClose={checkAlertClose}
      />
    </React.Fragment>
  );
}
