import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../../Url";
import { GetUserListDetails } from "./Model";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

type InitialState = {
  loading: boolean;
  patientDetailsListOfCompetitionIdRes: GetUserListDetails;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  patientDetailsListOfCompetitionIdRes: {} as GetUserListDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const getUserList = createAsyncThunk<GetUserListDetails>(
  "getUserList/getUserList",
  async (_, { rejectWithValue }) => {
    return await setupInterceptorsTo(axios)
      .get(Url.apiURL + "/patientapp/api/patient/patient_list", {
        headers,
      })
      .then((response) => {
        localStorage.setItem("defaultUserId", response.data.patientList[0].id);
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const userListSlice = createSlice({
  name: "getUserList",
  initialState,
  reducers: {
    updateUserListDetailsResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearUserListDetailsAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.patientDetailsListOfCompetitionIdRes = {} as GetUserListDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getUserList.fulfilled,
      (state, action: PayloadAction<GetUserListDetails>) => {
        state.loading = false;
        state.patientDetailsListOfCompetitionIdRes = action.payload;
        state.successMsg = "User list fetched successfully";
        state.errorMsg = "";
      }
    );
    builder.addCase(getUserList.rejected, (state, action) => {
      state.loading = false;
      state.patientDetailsListOfCompetitionIdRes = {} as GetUserListDetails;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateUserListDetailsResMsg, clearUserListDetailsAPIRes } =
  userListSlice.actions;
export default userListSlice.reducer;
