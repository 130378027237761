import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../../Url";
import {
  GetPatientDetailsListByCompetitionIdBody,
  GetPatientDetailsListByCompetitionIdDetails,
} from "./Model";
import { headers } from "../../../components/Utility";

type InitialState = {
  loading: boolean;
  patientDetailsListOfCompetitionIdRes: GetPatientDetailsListByCompetitionIdDetails;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  patientDetailsListOfCompetitionIdRes:
    {} as GetPatientDetailsListByCompetitionIdDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const patientDetailsListOfPatientByCompetitionId = createAsyncThunk<
  GetPatientDetailsListByCompetitionIdDetails,
  GetPatientDetailsListByCompetitionIdBody
>(
  "patientDetailsListOfPatientByCompetitionId/patientDetailsListOfPatientByCompetitionId",
  async (
    payload: GetPatientDetailsListByCompetitionIdBody,
    { rejectWithValue }
  ) => {
    return await axios
      .post(
        Url.apiURL +
          `/competitionapp/api/competitionPatientDetailsListAsParticipant/get_patientDetailsList_of_participant_by_competition`,
        // ?page=${Number(
        //   pageNo,
        // )}&size=50
        payload,
        {
          headers,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const patientDetailsListOfPatientByCompetitionIdSlice = createSlice({
  name: "patientDetailsListOfPatientByCompetitionId",
  initialState,
  reducers: {
    updatePatientDetailsListOfPatientByCompetitionIdResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearPatientDetailsListOfPatientByCompetitionIdAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.patientDetailsListOfCompetitionIdRes =
        {} as GetPatientDetailsListByCompetitionIdDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      patientDetailsListOfPatientByCompetitionId.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      patientDetailsListOfPatientByCompetitionId.fulfilled,
      (
        state,
        action: PayloadAction<GetPatientDetailsListByCompetitionIdDetails>
      ) => {
        state.loading = false;
        state.patientDetailsListOfCompetitionIdRes = action.payload;
        state.successMsg = "Image has been added successfully";
        state.errorMsg = "";
      }
    );
    builder.addCase(
      patientDetailsListOfPatientByCompetitionId.rejected,
      (state, action) => {
        state.loading = false;
        state.patientDetailsListOfCompetitionIdRes =
          {} as GetPatientDetailsListByCompetitionIdDetails;
        state.successMsg = "";
        state.errorMsg = String(action.payload!);
      }
    );
  },
});
export const {
  updatePatientDetailsListOfPatientByCompetitionIdResMsg,
  clearPatientDetailsListOfPatientByCompetitionIdAPIRes,
} = patientDetailsListOfPatientByCompetitionIdSlice.actions;
export default patientDetailsListOfPatientByCompetitionIdSlice.reducer;
