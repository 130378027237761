import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import {
  AddImageToCompetitionBody,
  AddImageToCompetitionDetails,
} from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  addPatientDetailsListInCompetitionIdRes: AddImageToCompetitionDetails;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  addPatientDetailsListInCompetitionIdRes: {} as AddImageToCompetitionDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const addPatientDetailsListInCompetitionId = createAsyncThunk<
  AddImageToCompetitionDetails,
  AddImageToCompetitionBody
>(
  "addPatientDetailsListInCompetitionId/addPatientDetailsListInCompetitionId",
  async (payload: AddImageToCompetitionBody, { rejectWithValue }) => {
    return await axios
      .post(
        Url.apiURL +
          `/competitionapp/api/competitionPatientDetailsListAsParticipant/add_patientDetail_of_participant_in_competition`,
        payload,
        {
          headers,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const addPatientDetailsListInCompetitionIdSlice = createSlice({
  name: "addPatientDetailsListInCompetitionId",
  initialState,
  reducers: {
    updateAddPatientDetailsListInCompetitionIdResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearAddPatientDetailsListInCompetitionIdAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.addPatientDetailsListInCompetitionIdRes =
        {} as AddImageToCompetitionDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPatientDetailsListInCompetitionId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      addPatientDetailsListInCompetitionId.fulfilled,
      (state, action: PayloadAction<AddImageToCompetitionDetails>) => {
        state.loading = false;
        state.addPatientDetailsListInCompetitionIdRes = action.payload;
        state.successMsg = action.payload.message;
        state.errorMsg = "";
      }
    );
    builder.addCase(
      addPatientDetailsListInCompetitionId.rejected,
      (state, action) => {
        state.loading = false;
        state.addPatientDetailsListInCompetitionIdRes =
          {} as AddImageToCompetitionDetails;
        state.successMsg = "";
        state.errorMsg = String(action.payload!);
      }
    );
  },
});
export const {
  updateAddPatientDetailsListInCompetitionIdResMsg,
  clearAddPatientDetailsListInCompetitionIdAPIRes,
} = addPatientDetailsListInCompetitionIdSlice.actions;
export default addPatientDetailsListInCompetitionIdSlice.reducer;
