import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { GetUserDetailsListValue } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  patientDropdownListRes: GetUserDetailsListValue;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  patientDropdownListRes: {} as GetUserDetailsListValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const getUserDetailsList = createAsyncThunk<GetUserDetailsListValue>(
  "getUserDetailsList/getUserDetailsList",
  async (_, { rejectWithValue }) => {
    return await axios
      .get(Url.apiURL + "/profileapp/api/userDetails/get_details", {
        headers,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const getUserDetailsSlice = createSlice({
  name: "getUserDetailsList",
  initialState,
  reducers: {
    updateGetUserDeatilsResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearGetUserDeatilsAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.patientDropdownListRes = {} as GetUserDetailsListValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetailsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getUserDetailsList.fulfilled,
      (state, action: PayloadAction<GetUserDetailsListValue>) => {
        state.loading = false;
        state.patientDropdownListRes = action.payload;
        state.successMsg = "User details fetched successfully";
        state.errorMsg = "";
      }
    );
    builder.addCase(getUserDetailsList.rejected, (state, action) => {
      state.loading = false;
      state.patientDropdownListRes = {} as GetUserDetailsListValue;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateGetUserDeatilsResMsg, clearGetUserDeatilsAPIRes } =
  getUserDetailsSlice.actions;
export default getUserDetailsSlice.reducer;
