import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import {
  UpdatePatientDataDetailsBody,
  UpdatePatientDataDetailsRes,
} from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  updatePatientDataDetailsRes: UpdatePatientDataDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  updatePatientDataDetailsRes: {} as UpdatePatientDataDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const updatePatientDataPoint = createAsyncThunk<
  UpdatePatientDataDetailsRes,
  UpdatePatientDataDetailsBody
>(
  "updatePatientDataPoint/updatePatientDataPoint",
  async (payload: UpdatePatientDataDetailsBody, { rejectWithValue }) => {
    return await axios
      .post(
        Url.apiURL + "/adminapp/api/patientDetails/edit_patientDetails",
        payload,
        { headers }
      )
      .then((response) => {
        localStorage.setItem("access", response.data.access);
        localStorage.setItem("refresh", response.data.refresh);
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const updatePatientDataPointSlice = createSlice({
  name: "updatePatientDataPoint",
  initialState,
  reducers: {
    updatePatientDataPointResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearPatientDataPointAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.updatePatientDataDetailsRes = {} as UpdatePatientDataDetailsRes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePatientDataPoint.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updatePatientDataPoint.fulfilled,
      (state, action: PayloadAction<UpdatePatientDataDetailsRes>) => {
        state.loading = false;
        state.updatePatientDataDetailsRes = action.payload;
        state.successMsg = "JWT Authentication Successful";
        state.errorMsg = "";
      }
    );
    builder.addCase(updatePatientDataPoint.rejected, (state, action) => {
      state.loading = false;
      state.updatePatientDataDetailsRes = {} as UpdatePatientDataDetailsRes;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updatePatientDataPointResMsg, clearPatientDataPointAPIRes } =
  updatePatientDataPointSlice.actions;
export default updatePatientDataPointSlice.reducer;
