import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../../Url";
import { ContestListRes } from "./Model";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

type InitialState = {
  loading: boolean;
  contestListRes: ContestListRes;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  contestListRes: {} as ContestListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const fetchContestList = createAsyncThunk<ContestListRes, Number>(
  "fetchContestList/fetchContestList",
  async (pageNo, { rejectWithValue }) => {
    return await setupInterceptorsTo(axios)
      .get(
        Url.apiURL +
          `/competitionapp/api/competitionAsParticipant/competition_list_by_participant?page=${pageNo}&size=${10}`,
        {
          headers,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const contestListSlice = createSlice({
  name: "fetchContestList",
  initialState,
  reducers: {
    updateContestListDetailsResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearContestListDetailsAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.contestListRes = {} as ContestListRes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContestList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchContestList.fulfilled,
      (state, action: PayloadAction<ContestListRes>) => {
        state.loading = false;
        state.contestListRes = action.payload;
        state.successMsg = "Contest list fetched successfully";
        state.errorMsg = "";
      }
    );
    builder.addCase(fetchContestList.rejected, (state, action) => {
      state.loading = false;
      state.contestListRes = {} as ContestListRes;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateContestListDetailsResMsg, clearContestListDetailsAPIRes } =
  contestListSlice.actions;
export default contestListSlice.reducer;
