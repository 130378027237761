import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
export const DrawerList = [
  {
    name: `Dashboard`, //"dashboard",
    path: "/dashboard",
    icon: <DashboardIcon style={{ color: "#183D3D", fontWeight: "bold" }} />,
  },
  {
    name: `Help`, //"help",
    path: "/needhelp",
    icon: <HelpIcon style={{ color: "#183D3D", fontWeight: "bold" }} />,
  },
  {
    name: `Profile`, //"profile",
    path: "/userprofile",
    icon: (
      <AccountCircleIcon style={{ color: "#183D3D", fontWeight: "bold" }} />
    ),
  },
  {
    name: `Logout`, //"logout",
    path: "/signin",
    icon: <LogoutIcon style={{ color: "#183D3D", fontWeight: "bold" }} />,
  },
];
