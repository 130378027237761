import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { AddPatientDataDetailsBody, AddPatientPointDataDetails } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  addPatientDataDetailsRes: AddPatientPointDataDetails;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  addPatientDataDetailsRes: {} as AddPatientPointDataDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const addPatientDetails = createAsyncThunk<
  AddPatientPointDataDetails,
  AddPatientDataDetailsBody
>(
  "addPatientDetails/addPatientDetails",
  async (payload: AddPatientDataDetailsBody, { rejectWithValue }) => {
    return await axios
      .post(
        Url.apiURL +
          `/adminapp/api/patientDetails/add_patientDetails_from_dashboard`,
        payload,
        {
          headers,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const addPatientDetailsSlice = createSlice({
  name: "addPatientDetails",
  initialState,
  reducers: {
    updateAddPatientDetailsResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearAddPatientDetailsAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.addPatientDataDetailsRes = {} as AddPatientPointDataDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPatientDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      addPatientDetails.fulfilled,
      (state, action: PayloadAction<AddPatientPointDataDetails>) => {
        state.loading = false;
        state.addPatientDataDetailsRes = action.payload;
        state.successMsg = action.payload.message;
        state.errorMsg = "";
      }
    );
    builder.addCase(addPatientDetails.rejected, (state, action) => {
      state.loading = false;
      state.addPatientDataDetailsRes = {} as AddPatientPointDataDetails;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateAddPatientDetailsResMsg, clearAddPatientDetailsAPIRes } =
  addPatientDetailsSlice.actions;
export default addPatientDetailsSlice.reducer;
