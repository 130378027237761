import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Container,
  Stack,
  Tab,
  Chip,
  Tabs,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MWPageTitle from "../components/MWPageTitle"; //"components/MWPageTitle";
import LinkedPicture from "./CompetitionDetails/LinkedPicture";
import UnLinkedPicture from "./CompetitionDetails/UnLinkedPicture";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch } from "react-redux";
import MWLoader from "../components/MWLoader";
import { CompetitionList } from "../redux/Dashboard/ContestList/Model";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { fetchContestList } from "../redux/Dashboard/ContestList/contestListSlice";
import { patientDetailsListOfPatientByCompetitionId } from "../redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/patientDetailsListOfPatientByCompetitionIdSlice";
import { GetPatientDetailsListByCompetitionIdBody } from "../redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/Model";
import { getUnLinkedImageListByUser } from "../redux/CompetitionDetails/UnlinkedUploadedImageListByUser/unlinkedUploadedImageListByUserSlice";
import AddImage from "./CompetitionDetails/AddImage";
import { useAppSelector } from "../redux/hooks";
import { AppDispatch } from "../redux/store";

export default function PatientDetailsListByCompetition() {
  const userId = localStorage.getItem("defaultUserId");
  const dispatch = useDispatch<AppDispatch>();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  const { competitionpageno } = useParams() as {
    competitionpageno: string;
  };
  const [value, setValue] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const linkedImageListRes = useAppSelector(
    (state) => state.getPatientDetailsByCompetitionRes
  );
  const unLinkedImageListRes = useAppSelector(
    (state) => state.getUnLinkedImageListByUserRes
  );

  const contestListRes = useAppSelector((state) => state.contestListRes);
  const contestListResFiltered: CompetitionList = _.filter(
    contestListRes.contestListRes.competitionList,
    ["id", Number(competitionid)]
  )[0];

  useEffect(() => {
    if (contestListResFiltered === undefined) {
      dispatch(fetchContestList(Number(competitionpageno)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contestListResFiltered]);

  useEffect(() => {
    dispatch(
      patientDetailsListOfPatientByCompetitionId(
        patientDetailsBody
        // , 1
      )
    );
    dispatch(
      getUnLinkedImageListByUser(
        patientDetailsBody
        // , 1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const patientDetailsBody = {
    competitionId: String(competitionid),
    patientId: String(userId),
  } as GetPatientDetailsListByCompetitionIdBody;

  const [addImageDialogEnable, setAddImageDialogEnable] = useState(false);
  const updateAddImageDialog = (passedVal: boolean) => {
    setAddImageDialogEnable(passedVal);
  };

  return (
    <Box>
      {linkedImageListRes.loading || unLinkedImageListRes?.loading ? (
        <MWLoader />
      ) : null}
      <Container maxWidth="xl" >
        <Stack
          direction="column"
          spacing={2}
          sx={{
            justifyContent: "flex-start",
            alignItems: "stretch",
          }}
          py={2}
        >
          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid>
              <MWPageTitle
                backButton={true}
                title={`${
                  contestListResFiltered !== undefined
                    ? contestListResFiltered?.displayName + " details"
                    : ""
                }`}
                enableCount={true}
                reload={true}
              />
            </Grid>
            {value === "2" ? (
              <Grid>
                <Grid
                  container
                  direction="row"
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Grid>
                    <Button
                      variant="outlined"
                      onClick={() => setAddImageDialogEnable(true)}
                    >
                      Add New Patient Details
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                value="1"
                label={
                  <Grid
                    container
                    direction="row"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    spacing={1}
                  >
                    <Grid>
                      <Typography>Active Picture</Typography>
                    </Grid>
                    <Grid>
                      <Chip
                        label={
                          linkedImageListRes
                            ?.patientDetailsListOfCompetitionIdRes?.count
                        }
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                }
                icon={
                  <RefreshIcon
                    onClick={() => {
                      dispatch(
                        patientDetailsListOfPatientByCompetitionId(
                          patientDetailsBody
                          // 1
                        )
                      );
                    }}
                  />
                }
                iconPosition="end"
              />
              <Tab
                value="2"
                label={
                  <Grid
                    container
                    direction="row"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    spacing={1}
                  >
                    <Grid>
                      <Typography>Inactive Picture</Typography>
                    </Grid>
                    <Grid>
                      <Chip
                        label={
                          unLinkedImageListRes
                            ?.patientDetailsListOfCompetitionIdRes?.count
                        }
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                }
                icon={
                  <RefreshIcon
                    onClick={() => {
                      dispatch(
                        getUnLinkedImageListByUser(
                          patientDetailsBody
                          // , 1
                        )
                      );
                    }}
                  />
                }
                iconPosition="end"
              />
            </Tabs>
          </Box>
          {value === "1" ? <LinkedPicture /> : <UnLinkedPicture />}
        </Stack>
      </Container>
      {addImageDialogEnable ? (
        <AddImage
          enable={addImageDialogEnable}
          dialogUpdateState={updateAddImageDialog}
        />
      ) : null}
    </Box>
  );
}
