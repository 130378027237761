import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emailOtpGeneration } from "../../redux/emailOtpGeneration/emailOtpGenerationSlice";
import { emailOtpGenerationDetails } from "../../redux/emailOtpGeneration/Model";
import { emailOtpAuthDetails } from "../../redux/emailOtpAuth/Model";
import { emailOtpAuth } from "../../redux/emailOtpAuth/emailOtpAuthSlice";
import { Alert, Box, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";

export default function Loginwithotpcomponent() {
  const dispatch = useDispatch<AppDispatch>();
  const [otpemail, setOtpEmail] = useState("");
  const [errorotpemail, setErrorOtpEmail] = useState("");
  const [isDisableOTPField, setIsDisableOTPField] = useState(true);
  const [otpStatus, setOtpStatus] = useState("");
  const [otpMsg, setOtpMsg] = useState("");
  const [otpinvlid, setOtpInvalid] = useState("");
  const [buttonText, setButtonText] = useState("SEND OTP");
  const [validotp, setvalidOtp] = useState("");
  const [errorotp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = React.useState(false);
  const [loginLoading, setLoginLoading] = React.useState(false);
  const navigate = useNavigate();

  const errorOtpEmail = errorotpemail ? (
    <Typography>{errorotpemail}</Typography>
  ) : (
    ""
  );

  const closeSuccessBanner = () => {
    setOtpMsg("");
  };

  const closeErrorBanner = () => {
    setOtpInvalid("");
  };

  const OTPText = otpMsg ? (
    <Alert
      variant="standard"
      severity={otpStatus === "Failed" ? "error" : "success"}
      onClose={() => closeSuccessBanner()}
    >
      <p>{otpMsg}</p>
    </Alert>
  ) : (
    ""
  );

  const errorOtp = errorotp ? <Typography>{errorotp}</Typography> : "";

  const wrongOtp = otpinvlid ? (
    <Alert
      variant="standard"
      severity="error"
      title={errorotp}
      onClose={() => closeErrorBanner()}
    >
      <p>{otpinvlid}</p>
    </Alert>
  ) : (
    ""
  );

  const emailotpbody = {
    email: otpemail.toLowerCase(),
  } as emailOtpGenerationDetails;

  const otpbody = {
    email: otpemail.toLowerCase(),
    otp: validotp,
  } as emailOtpAuthDetails;

  //handle button click of otp generation
  const handleLoginwithotp = () => {
    if (otpemail === "") {
      setErrorOtpEmail("Please enter email id");
      return;
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        otpemail.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(otpemail.toLowerCase()) === false
    ) {
      setErrorOtpEmail("Please enter valid email id");
    } else {
      setErrorOtpEmail("");
      dispatch(emailOtpGeneration(emailotpbody));
    }
  };
  const emailOtpGenRes = useAppSelector((state) => state.emailOtpGeneration);
  const emailOtpAuthRes = useAppSelector((state) => state.emailOtpAuth);
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLoginwithValidotp();
    }
  };
  useEffect(() => {
    emailOtpGenRes?.loading ? setOtpLoading(true) : setOtpLoading(false);
  }, [emailOtpGenRes]);

  useEffect(() => {
    emailOtpAuthRes?.loading ? setLoginLoading(true) : setLoginLoading(false);
  }, [emailOtpAuthRes]);

  useEffect(() => {
    if (emailOtpGenRes?.emailOtpGenerationRes?.message !== undefined) {
      setOtpMsg(emailOtpGenRes?.emailOtpGenerationRes?.message);
      setOtpStatus("success");
      setIsDisableOTPField(false);
      setButtonText("Resend OTP");
    } else {
      setOtpMsg(emailOtpGenRes?.errorMsg);
      setOtpStatus("failed");
      setLoginLoading(false);
      setButtonText("Send OTP");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpGenRes]);

  // otp validation
  const handleLoginwithValidotp = () => {
    if (
      validotp !== "" &&
      new RegExp(/^\d{6}$/gm).test(validotp.toLowerCase())
    ) {
      dispatch(emailOtpAuth(otpbody));
    } else {
      setOtp("Please enter valid OTP");
      return;
    }
  };

  useEffect(() => {
    if (emailOtpAuthRes?.emailOtpAuthRes?.token !== undefined) {
      window.location.reload();
      navigate("/dashboard");
    } else {
      setOtpInvalid(emailOtpAuthRes?.errorMsg);
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpAuth]);

  return (
    <Box sx={{ minWidth: 350 }} onKeyDown={keyDownEvent}>
      <TextField
        fullWidth
        sx={{ my: 2 }}
        error={errorOtpEmail === "" ? false : true}
        value={otpemail}
        label={"Email or Phone No"}
        placeholder="Pleace Enter Email or Phone No"
        id="emailFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setOtpEmail(event.target.value);
          setErrorOtpEmail("");
        }}
        size="small"
        helperText={
          errorOtpEmail === ""
            ? "Please provide email or phone number"
            : errorOtpEmail
        }
      />
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        loading={otpLoading}
        loadingIndicator="Loading…"
        onClick={handleLoginwithotp}
      >
        {buttonText}
      </LoadingButton>
      <Box>{OTPText}</Box>

      {/*............ for validate otp code .............. */}
      <TextField
        fullWidth
        sx={{ my: 2 }}
        value={validotp}
        error={errorOtp === "" ? false : true}
        label={"OTP"}
        placeholder={"OTP"}
        id="OTPFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setvalidOtp(event.target.value);
          setOtp("");
        }}
        disabled={isDisableOTPField}
        size="small"
        helperText={errorOtp === "" ? "Please provide OTP" : errorOtp}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleLoginwithValidotp}
        disabled={isDisableOTPField}
        loading={loginLoading}
        loadingIndicator="Loading…"
      >
        Log In
      </LoadingButton>
      <Box>{wrongOtp}</Box>
    </Box>
  );
}
