import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { LogOutBody, LogOutResponse } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  logOutRes: LogOutResponse;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  logOutRes: {} as LogOutResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const logOut = createAsyncThunk<LogOutResponse, LogOutBody>(
  "logOut/logOut",
  async (payload: LogOutBody, { rejectWithValue }) => {
    return await axios
      .post(Url.apiURL + "/profileapp/logout/", payload, { headers })
      .then((response) => {
        localStorage.clear();
        window.location.reload();
        return response.data;
      })
      .catch((error) => {
        // Check if error response exists
        if (error.response) {
          return rejectWithValue(
            error?.response?.data?.message !== undefined
              ? error.response?.data?.message
              : error.response.data.non_field_errors !== undefined
              ? error.response.data.non_field_errors[0]
              : "Something went wrong"
          ); // Send API error data
        }
        return rejectWithValue(error.message);
      });
  }
);

const logOutSlice = createSlice({
  name: "logOut",
  initialState,
  reducers: {
    updateLogOutResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearLogOutAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.logOutRes = {} as LogOutResponse;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logOut.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      logOut.fulfilled,
      (state, action: PayloadAction<LogOutResponse>) => {
        state.loading = false;
        state.logOutRes = action.payload;
        state.successMsg = "Logout Successful";
        state.errorMsg = "";
      }
    );
    builder.addCase(logOut.rejected, (state, action) => {
      state.loading = false;
      state.logOutRes = {} as LogOutResponse;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateLogOutResMsg, clearLogOutAPIRes } = logOutSlice.actions;
export default logOutSlice.reducer;
