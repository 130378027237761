import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  loading: boolean;
};
const initialState: InitialState = {
  loading: false,
};

const appLoaderSlice = createSlice({
  name: "appLoader",
  initialState,
  reducers: {
    updateAppLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});
export const { updateAppLoader } = appLoaderSlice.actions;
export default appLoaderSlice.reducer;
