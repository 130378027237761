import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWExceptionList from "../../../components/MWExceptionList";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { patientDetailsListOfPatientByCompetitionId } from "../../../redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/patientDetailsListOfPatientByCompetitionIdSlice";
import {
  GetPatientDetailsListByCompetitionIdBody,
  PatientDetailsList,
} from "../../../redux/CompetitionDetails/PatientDetailsListOfPatientByCompetitionId/Model";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DocumentViewer from "../../DocumentViewer";
import { useAppSelector } from "../../../redux/hooks";
import { AppDispatch } from "../../../redux/store";

export default function LinkedPicture() {
  const userId = localStorage.getItem("defaultUserId");
  const dispatch = useDispatch<AppDispatch>();
  const { competitionid } = useParams() as {
    competitionid: string;
  };
  useEffect(() => {
    dispatch(
      patientDetailsListOfPatientByCompetitionId({
        ...patientDetailsBody,
        pageNo: page,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(
      patientDetailsListOfPatientByCompetitionId({
        ...patientDetailsBody,
        pageNo: value,
      })
    );
  };

  const patientDetailsBody = {
    competitionId: String(competitionid),
    patientId: String(userId!),
  } as GetPatientDetailsListByCompetitionIdBody;

  const patientPointDataList = useAppSelector(
    (state) => state.getPatientDetailsByCompetitionRes
  );
  const [dataGridArray, setDataArray] = useState([] as any[]);

  const [pageListCount, setPageListCount] = useState("");
  // const [listCount, setListCount] = useState("");

  useEffect(() => {
    patientPointDataList?.patientDetailsListOfCompetitionIdRes
      ?.numberOfPages !== undefined
      ? setPageListCount(
          String(
            patientPointDataList?.patientDetailsListOfCompetitionIdRes
              ?.numberOfPages
          )
        )
      : setPageListCount("0");

    if (
      patientPointDataList?.patientDetailsListOfCompetitionIdRes
        ?.patientDetailsList !== undefined
    ) {
      let dataPointArr =
        patientPointDataList?.patientDetailsListOfCompetitionIdRes?.patientDetailsList.map(
          (element: PatientDetailsList) => ({
            id: element.id,
            description: element.description,
            startDate: dayjs(element.startDate).format(
              "dddd, MMMM D, YYYY h:mm A"
            ),
            date: element.startDate,
            image:
              element?.patientDocument?.document !== undefined
                ? element?.patientDocument?.document
                : "",
            imageId:
              element?.patientDocument?.id !== undefined
                ? String(element?.patientDocument?.id)
                : undefined,
            category: "linked",
          })
        );
      setDataArray(dataPointArr);
    } else {
      setDataArray([] as any[]);
      setPageListCount(
        String(
          patientPointDataList?.patientDetailsListOfCompetitionIdRes
            ?.numberOfPages
        )
      );
    }
  }, [patientPointDataList]);
  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      width: 150,
      align: "left",
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setViewImageDialogEnable(true);
            setSelectedImage(params.row.image);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      width: 230,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "id",
      headerName: "Patient Details Id",
      type: "string",
      width: 150,
      editable: true,
      sortable: false,
      align: "left",
      disableColumnMenu: true,
    },
  ];

  const [viewImageDialogEnable, setViewImageDialogEnable] = useState(false);
  const updateViewImageDialog = (passedVal: boolean) => {
    setViewImageDialogEnable(passedVal);
  };
  const [selectedImage, setSelectedImage] = useState(false);
  const updateSelectedImageDialog = (passedVal: any) => {
    setSelectedImage(passedVal);
  };
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
              </Grid>
              <Grid item>
                <Pagination
                  color="primary"
                  count={Number(pageListCount)}
                  page={page}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {dataGridArray.length > 0 ? (
            // <DataGrid
            //   rows={dataGridArray}
            //   columns={columns}
            //   hideFooter
            //   hideFooterPagination
            //   disableSelectionOnClick
            //   experimentalFeatures={{ newEditingApi: true }}
            //   autoHeight
            //   components={{ Toolbar: GridToolbar }}
            //   componentsProps={{
            //     toolbar: {
            //       csvOptions: { disableToolbarButton: true },
            //       printOptions: { disableToolbarButton: true },
            //       showQuickFilter: true,
            //       quickFilterProps: { debounceMs: 250 },
            //     },
            //   }}
            //   disableColumnFilter
            //   disableColumnSelector
            //   disableDensitySelector
            // />
            <DataGrid
              rows={dataGridArray}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              slots={{ toolbar: GridToolbar }}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {viewImageDialogEnable ? (
        <DocumentViewer
          enable={viewImageDialogEnable}
          dialogUpdateState={updateViewImageDialog}
          selectedItem={selectedImage}
          selectedItemUpdateValue={updateSelectedImageDialog}
        />
      ) : null}
    </Box>
  );
}
