import React from "react";
import Errorimage from "../image/design-assets/error-page.png";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

function NotFound() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container maxWidth="xl" sx={{ height: "100vh" }} title="Page Not Found">
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              height: "100vh",
            }}
          >
            <Box
              mt={10}
              style={{
                backgroundImage: fullScreen ? `url(${Errorimage})` : "none",
                backgroundSize: fullScreen ? "cover" : "none",
                backgroundPosition: fullScreen ? "center" : "none",
                height: fullScreen ? "100vh" : "none",
              }}
            >
              <Typography variant="h4">Something went wrong</Typography>
              <p>
                The specified file was not found on this website. Please check
                the URL for mistakes and try again.
                <br />
              </p>
              <Box>
                <Button
                  variant="contained"
                  href="/"
                  sx={{ textTransform: "none" }}
                >
                  Get back to Home
                </Button>
              </Box>
            </Box>
          </Stack>
        </Grid>
        <Grid size={6} display={{ xs: "none", sm: "block" }}>
          <Box mt={10}>
            <img
              width="750vh"
              height="600vh"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={Errorimage}
              alt=""
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NotFound;
