import React, { useEffect } from "react";
import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
// import { t } from "i18next";
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  DatePicker,
  // DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MWLoader from "../components/MWLoader";
import MWAutocomplete from "../components/MWAutocomplete";
import { addNeedHelp } from "../redux/needHelp/addNeedHelp/addNeedHelpSlice";
import { patientList } from "../redux/needHelp/patientList/patientListSlice";
import { needHelpBody } from "../redux/needHelp/addNeedHelp/Model";
import { AppDispatch } from "../redux/store";
import { useAppSelector } from "../redux/hooks";

export default function NeedHelp() {
  const dispatch = useDispatch<AppDispatch>();
  const [helpPhoneNumber, setHelpPhoneNumber] = useState("");
  const [textValue, setTextValue] = useState("");
  const [patientName, setPatientName] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePhoneNumber = useCallback(
    (value: any) => setHelpPhoneNumber(value.target.value),
    []
  );
  const handleChange = useCallback(
    (newValue: any) => setTextValue(newValue.target.value),
    []
  );
  // const handleNameChange = useCallback(
  //   (value) => setSelectedPatientName(value.target.value),
  //   []
  // );
  const [selectedPatientName, setSelectedPatientName] = useState<number | null>(
    null
  );
  const patientValueFromChild = async (passedval: any) => {
    setSelectedPatientName(passedval);
  };
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  // const [selectedPatientName, setSelectedPatientName] = useState("");
  const [helpQueryMessage, setHelpQueryMessage] = useState("");
  const [helpQueryError, setHelpQueryError] = useState("");
  const handleBannerDismiss = () => {
    setHelpQueryMessage("");
    setHelpQueryError("");
  };

  const successMessage = helpQueryMessage ? (
    <Alert
      severity="success"
      onClose={() => {
        handleBannerDismiss();
      }}
    >
      <Typography>{helpQueryMessage}</Typography>
    </Alert>
  ) : (
    ""
  );

  const errorHelpDiv = helpQueryError ? (
    <Alert
      severity="error"
      onClose={() => {
        handleBannerDismiss();
      }}
    >
      <Typography>{helpQueryError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [selectedDate, setSelectedDate] = React.useState<Date | null| any>();
  //new Date(''),
  // const today = new Date();
  const handleDateChange = (date: Date | null | undefined) => {
    setSelectedDate(date !== undefined && date !== null ? date : null);
    console.log(selectedDate);
  };

  const addNeedHelpValue = useAppSelector((state) => state.addNeedHelp);
  const dropdownListValue = useAppSelector((state) => state.dropdown);

  // Submit help api call section  ......
  const submitQuaryBody = {
    queryText: textValue,
    phoneNumber: helpPhoneNumber,
    patientId: String(selectedPatientName),
  };
  const needHelp = async () => {
    dispatch(addNeedHelp(submitQuaryBody as needHelpBody));
  };

  useEffect(() => {
    setIsLoading(addNeedHelpValue.loading);
    setHelpQueryMessage(addNeedHelpValue.needHelpDetails.message);
  }, [addNeedHelpValue]);

  useEffect(() => {
    setIsLoading(dropdownListValue.loading);
    if (dropdownListValue?.patientList?.patientList !== undefined) {
      setPatientName(dropdownListValue.patientList.patientList);
    }
  }, [dropdownListValue]);

  useEffect(() => {
    dispatch(patientList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageBody = (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
      spacing={2}
    >
      <Grid size={{ xs: 4, sm: 4, md: 4, lg: 4 }}>
        <Typography variant="h6" gutterBottom>
          Confused whether to take 2nd opinion?
          {/* {t("needhelppage.deschead", { ns: ["home"] })!} */}
        </Typography>
        <Typography sx={{ color: "#1E90FF" }}>
          Need help to get right doctor consultation? Need assistance in
          uploading medical record? We will be happy to help.
          {/* {t("needhelppage.desctext", { ns: ["home"] })!} */}
        </Typography>
      </Grid>
      <Grid size={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
        <Card>
          <CardHeader title="Get call back from Cloud Doctor"></CardHeader>

          <Grid
            container
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Grid size={{ xs: 7, sm: 7, md: 7, lg: 7 }}>
              <Box p={2}>
                <TextField
                  label={
                    "Patient Phone Number"
                    // t("needhelppage.patientphnno", { ns: ["home"] })!
                  }
                  value={helpPhoneNumber}
                  placeholder={
                    "Please Enter Your Phone number"
                    // t("needhelppage.phnnodesc", { ns: ["home"] })!
                  }
                  id="othersignificantillness"
                  onChange={handleChangePhoneNumber}
                  variant="outlined"
                  fullWidth
                />
                <Box>
                  <Typography sx={{ pt: 4 }}>
                    Patient Name (Optional)
                    {/* {t("needhelppage.patiename", { ns: ["home"] })!} */}
                  </Typography>
                  <MWAutocomplete
                    placeHolder="Patient List"
                    listvalue={patientName}
                    selectedItem={selectedPatientName}
                    dropdownValue={patientValueFromChild}
                  />
                  <br />
                </Box>
                <Box>
                  <Typography sx={{ pt: 4 }}>
                    Preferred Date (Optional)
                    {/* {t("needhelppage.date", { ns: ["home"] })!} */}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Controlled picker"
                      value={selectedDate}
                      onChange={(newValue: any) => handleDateChange(newValue)}
                      disablePast
                    />
                    {/* <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={today}
                      value={selectedDate}
                      onChange={(date) => handleDateChange(date!)}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                          size=""
                        />
                      )}
                      InputAdornmentProps={{ position: "start" }}
                    /> */}
                  </LocalizationProvider>
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 5, sm: 5, md: 5, lg: 5 }}>
              <Box p={2}>
                <TextField
                  label={
                    "Description (Optional)"
                    // t("needhelppage.desc", { ns: ["home"] })!
                  }
                  value={textValue}
                  onChange={handleChange}
                  placeholder={
                    "Please describe patient's problem"
                    // t("needhelppage.oppdesc", { ns: ["home"] })!
                  }
                  maxRows={11}
                  minRows={11}
                  fullWidth
                  multiline
                />
              </Box>
            </Grid>
          </Grid>
          <Box style={{ float: "right" }} p={2}>
            <Button variant="contained" onClick={needHelp}>
              Submit
              {/* {t("needhelppage.submit", { ns: ["home"] })!} */}
            </Button>
          </Box>
          <Box my={3}>{successMessage}</Box>
          <Box my={3}>{errorHelpDiv}</Box>
        </Card>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {loadingMarkup}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <Typography variant="h4" gutterBottom>
          Need Help
          {/* {t("needhelppage.needhelp", { ns: ["home"] })!} */}
        </Typography>
        {pageBody}
      </Container>
    </Box>
  );
}
