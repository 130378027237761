import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../../Url";
import {
  GetUnLinkedImageListByUserBody,
  GetUnLinkedImageListByUserDetails,
} from "./Model";
import { headers } from "../../../components/Utility";

type InitialState = {
  loading: boolean;
  patientDetailsListOfCompetitionIdRes: GetUnLinkedImageListByUserDetails;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  patientDetailsListOfCompetitionIdRes: {} as GetUnLinkedImageListByUserDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const getUnLinkedImageListByUser = createAsyncThunk<
  GetUnLinkedImageListByUserDetails,
  GetUnLinkedImageListByUserBody
>(
  "getUnLinkedImageListByUser/getUnLinkedImageListByUser",
  async (payload: GetUnLinkedImageListByUserBody, { rejectWithValue }) => {
    return await axios
      .post(
        Url.apiURL +
          `/adminapp/api/patientDetails/get_patientDetailsList_from_dashboard`,
        payload,
        {
          headers,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const unlinkedUploadedImageListByUserSlice = createSlice({
  name: "getUnLinkedImageListByUser",
  initialState,
  reducers: {
    updateUnlinkedUploadedImageListByUserResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearUnlinkedUploadedImageListByUserAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.patientDetailsListOfCompetitionIdRes =
        {} as GetUnLinkedImageListByUserDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnLinkedImageListByUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getUnLinkedImageListByUser.fulfilled,
      (state, action: PayloadAction<GetUnLinkedImageListByUserDetails>) => {
        state.loading = false;
        state.patientDetailsListOfCompetitionIdRes = action.payload;
        state.successMsg = "Image has been added successfully";
        state.errorMsg = "";
      }
    );
    builder.addCase(
      getUnLinkedImageListByUser.rejected,
      (state, action) => {
        state.loading = false;
        state.patientDetailsListOfCompetitionIdRes =
          {} as GetUnLinkedImageListByUserDetails;
        state.successMsg = "";
        state.errorMsg = String(action.payload!);
      }
    );
  },
});
export const {
  updateUnlinkedUploadedImageListByUserResMsg,
  clearUnlinkedUploadedImageListByUserAPIRes,
} = unlinkedUploadedImageListByUserSlice.actions;
export default unlinkedUploadedImageListByUserSlice.reducer;
