import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { ResetPasswordDetails, ResetPasswordValue } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  resetPasswordRes: ResetPasswordValue;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  resetPasswordRes: {} as ResetPasswordValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const resetPassword = createAsyncThunk<
  ResetPasswordValue,
  ResetPasswordDetails
>(
  "resetPassword/resetPassword",
  async (payload: ResetPasswordDetails, { rejectWithValue }) => {
    return await axios
      .post(Url.apiURL + "/profileapp/reset-password/", payload, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    updateSetEmailOrPhoneResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearSetEmailOrPhoneAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.resetPasswordRes = {} as ResetPasswordValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      resetPassword.fulfilled,
      (state, action: PayloadAction<ResetPasswordValue>) => {
        state.loading = false;
        state.resetPasswordRes = action.payload;
        state.successMsg = "Password reset Successful";
        state.errorMsg = "";
      }
    );
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
      state.resetPasswordRes = {} as ResetPasswordValue;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateSetEmailOrPhoneResMsg, clearSetEmailOrPhoneAPIRes } =
  resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
