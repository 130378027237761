import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../../Url";
import { needHelpBody, needHelpValue } from "./Model";
import { headers } from "../../../components/Utility";

type InitialState = {
  loading: boolean;
  needHelpDetails: needHelpValue;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  needHelpDetails: {} as needHelpValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const addNeedHelp = createAsyncThunk<needHelpValue, needHelpBody>(
  "addNeedHelp/addNeedHelp",
  async (payload: needHelpBody, { rejectWithValue }) => {
    return await axios
      .post(Url.apiURL + "/patientapp/api/patienthelp/submit_query", payload, {
        headers,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const addNeedHelpSlice = createSlice({
  name: "addNeedHelp",
  initialState,
  reducers: {
    updateAddNeedHelpResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearAddNeedHelpAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.needHelpDetails = {} as needHelpValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addNeedHelp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      addNeedHelp.fulfilled,
      (state, action: PayloadAction<needHelpValue>) => {
        state.loading = false;
        state.needHelpDetails = action.payload;
        state.successMsg = "JWT Authentication Successful";
        state.errorMsg = "";
      }
    );
    builder.addCase(addNeedHelp.rejected, (state, action) => {
      state.loading = false;
      state.needHelpDetails = {} as needHelpValue;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateAddNeedHelpResMsg, clearAddNeedHelpAPIRes } =
  addNeedHelpSlice.actions;
export default addNeedHelpSlice.reducer;
