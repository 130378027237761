import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { ForgotPasswordBody, ForgotPasswordRes } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  forgotPasswordRes: ForgotPasswordRes;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  forgotPasswordRes: {} as ForgotPasswordRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const forgotPassword = createAsyncThunk<
  ForgotPasswordRes,
  ForgotPasswordBody
>(
  "forgotPassword/forgotPassword",
  async (payload: ForgotPasswordBody, { rejectWithValue }) => {
    return await axios
      .post(Url.apiURL + "/profileapp/forgot-password", payload, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    updateForgotPasswordResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearForgotPasswordAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.forgotPasswordRes = {} as ForgotPasswordRes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      forgotPassword.fulfilled,
      (state, action: PayloadAction<ForgotPasswordRes>) => {
        state.loading = false;
        state.forgotPasswordRes = action.payload;
        state.successMsg = action.payload.message;
        state.errorMsg = "";
      }
    );
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false;
      state.forgotPasswordRes = {} as ForgotPasswordRes;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateForgotPasswordResMsg, clearForgotPasswordAPIRes } =
  forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
