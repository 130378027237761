import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { emailOtpAuthDetails, emailOtpAuthValue } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  emailOtpAuthRes: emailOtpAuthValue;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  emailOtpAuthRes: {} as emailOtpAuthValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const emailOtpAuth = createAsyncThunk<
  emailOtpAuthValue,
  emailOtpAuthDetails
>(
  "emailOtpAuth/emailOtpAuth",
  async (payload: emailOtpAuthDetails, { rejectWithValue }) => {
    return await axios
      .post(Url.apiURL + "/patientapp/email-otp-auth/", payload, {
        headers,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const emailOtpAuthSlice = createSlice({
  name: "emailOtpAuth",
  initialState,
  reducers: {
    updateEmailOTPAuthResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearEmailOTPAuthAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.emailOtpAuthRes = {} as emailOtpAuthValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(emailOtpAuth.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      emailOtpAuth.fulfilled,
      (state, action: PayloadAction<emailOtpAuthValue>) => {
        state.loading = false;
        state.emailOtpAuthRes = action.payload;
        state.successMsg = action.payload.message;
        state.errorMsg = "";
      }
    );
    builder.addCase(emailOtpAuth.rejected, (state, action) => {
      state.loading = false;
      state.emailOtpAuthRes = {} as emailOtpAuthValue;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateEmailOTPAuthResMsg, clearEmailOTPAuthAPIRes } =
  emailOtpAuthSlice.actions;
export default emailOtpAuthSlice.reducer;
