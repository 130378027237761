import React, { useState, SyntheticEvent } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Tab,
  Divider,
} from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import Loginwithotpcomponent from "./Loginwithotpcomponent";
import Loginwithpasswordcomponent from "./Loginwithpasswordcomponent";
import OnboardingLayout from "./OnboardingLayout";

export default function Login() {
  const handleTabChange = (_event: SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [selected, setSelected] = useState("emaillogin");

  const tabs = [
    {
      id: "emaillogin",
      content: "Sign in with OTP",
      body: <Loginwithotpcomponent />,
      panelID: "emaillogin-content",
    },
    {
      id: "passwordlogin",
      content: "Sign in with Password",
      body: <Loginwithpasswordcomponent />,
      accessibilityLabel: "All customers",
      panelID: "passwordlogin-content",
    },
  ];

  const actualPageMarkup = (
    <Box>
      <Divider></Divider>
      <Box my={2}>
        <Typography color="textPrimary" variant="h6" display="inline">
          <b>New User? &nbsp;&nbsp;</b>
          <Button variant="outlined" color="secondary" href="/signup">
            Register
          </Button>
        </Typography>
      </Box>
      <Divider>
        <b>Old User?</b>
      </Divider>
      <Box my={2} style={{ height: "350px" }}>
        <TabContext value={selected}>
          <TabList
            onChange={handleTabChange}
            centered
            aria-label="account-settings tabs"
            sx={{
              backgroundColor: "transparent",
              borderBottom: "1px solid rgba(254, 219, 212, 1)",
              borderRadius: "0px",
            }}
          >
            <Tab
              value={tabs[0].id}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {tabs[0].content}
                </Box>
              }
            />
            <Tab
              value={tabs[1].id}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {tabs[1].content}
                </Box>
              }
            />
          </TabList>
          <TabPanel sx={{ p: 0 }} value={tabs[0].id}>
            {tabs[0].body}
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value={tabs[1].id}>
            {tabs[1].body}
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="xl">
      <OnboardingLayout>{actualPageMarkup}</OnboardingLayout>
    </Container>
  );
}
