import React from "react";
import Login from "../pages/PatientOnBoarding/Login";
import Signup from "../pages/PatientOnBoarding/Signup";
import NotFound from "../pages/NotFound";
import NeedHelp from "../pages/NeedHelp";
import Dashboard from "../pages/Dashboard";
import Userprofile from "../pages/UserProfile/Userprofile";
import CompetitionDetails from "../pages/CompetitionDetails";
import { Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "../Authentication/PrivateRoutes";

function ComponentRouter() {
  const token = localStorage.getItem("access");
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={token ? <PrivateRoutes component={Dashboard} /> : <Login />}
        />
        <Route
          path="/signin"
          element={token ? <PrivateRoutes component={Dashboard} /> : <Login />}
        />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/dashboard"
          element={<PrivateRoutes component={Dashboard} />}
        />
        <Route
          path="/needhelp"
          element={<PrivateRoutes component={NeedHelp} />}
        />
        <Route
          path="/competitiondetails/:competitionpageno?/:competitionid?"
          element={<PrivateRoutes component={CompetitionDetails} />}
        />
        <Route
          path="/needhelp"
          element={<PrivateRoutes component={NeedHelp} />}
        />
        <Route
          path="/userprofile"
          element={<PrivateRoutes component={Userprofile} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default ComponentRouter;
