import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../Url";
import { emailOtpSignupDetails, emailOtpSignupValue } from "./Model";
import { headers } from "../../components/Utility";

type InitialState = {
  loading: boolean;
  emailOtpSignupRes: emailOtpSignupValue;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  emailOtpSignupRes: {} as emailOtpSignupValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const emailOtpSignup = createAsyncThunk<
  emailOtpSignupValue,
  emailOtpSignupDetails
>(
  "emailOtpSignup/emailOtpSignup",
  async (payload: emailOtpSignupDetails, { rejectWithValue }) => {
    return await axios
      .post(Url.apiURL + "/patientapp/email-otp-signup/", payload, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const emailOtpSignupSlice = createSlice({
  name: "emailOtpSignup",
  initialState,
  reducers: {
    updateEmailOtpSignupResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearEmailOtpSignupAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.emailOtpSignupRes = {} as emailOtpSignupValue;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(emailOtpSignup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      emailOtpSignup.fulfilled,
      (state, action: PayloadAction<emailOtpSignupValue>) => {
        state.loading = false;
        state.emailOtpSignupRes = action.payload;
        state.successMsg = action.payload.message;
        state.errorMsg = "";
      }
    );
    builder.addCase(emailOtpSignup.rejected, (state, action) => {
      state.loading = false;
      state.emailOtpSignupRes = {} as emailOtpSignupValue;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const { updateEmailOtpSignupResMsg, clearEmailOtpSignupAPIRes } =
  emailOtpSignupSlice.actions;
export default emailOtpSignupSlice.reducer;
