import React, { useEffect, useState } from "react";
// import { t } from "i18next";
import { BrowserRouter as Router } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  Stack,
  IconButton,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MenuIcon from "@mui/icons-material/Menu";
import { DrawerList } from "./components/DrawerList";
import ComponentRouter from "./routes";
import AdminLoader from "./components/MWLoader";
import medicalWISDOM from "./image/logo/medicalWISDOM.png";
import { GetPatientDetailsRes } from "./redux/jwtPatientLogIn/patientLoginDetails/Model";
import { LogOutBody } from "./redux/logOut/Model";
import { logOut } from "./redux/logOut/logOutSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./redux/hooks";
import { AppDispatch } from "./redux/store";
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  windows?: () => Window;
}
const drawerWidth = 240;

export default function App(props: Props) {
  const { windows } = props;
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const dispatch = useDispatch<AppDispatch>();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Token = localStorage.getItem("access");

  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetPatientDetailsRes;

  const logOutBody = {
    access: Token!,
  } as LogOutBody;

  async function handleLogout() {
    dispatch(logOut(logOutBody));
  }

  const logOutRes = useAppSelector((state) => state.logOutRes);
  useEffect(() => {
    setIsLoading(logOutRes?.loading);
    if (logOutRes?.successMsg !== "") {
      localStorage.clear();
      window.location.reload();
    }
    if (logOutRes?.errorMsg !== "") {
      localStorage.clear();
      window.location.reload();
    }
  }, [logOutRes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    windows !== undefined ? () => windows().document.body : undefined;
  const drawer = (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      py={1}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          width: "100%",
        }}
      >
        <Toolbar />
        <List
          style={{
            width: "100%",
          }}
          dense
        >
          {DrawerList.slice(0, 1).map((item) => {
            return (
              <ListItem
                component={Link}
                // activeClassName="Mui-selected"
                href={item.path}
                key={item.name}
                disablePadding
                // onClick={() => navigate(`${item.path}`)}
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      `${item.name}`
                      // t(`menu.${item.name}`, { ns: ["home"] })!
                    }
                    // primaryTypographyProps={{
                    //   fontSize: 14,
                    //   fontWeight: "medium",
                    // }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
          <Divider sx={{ bgcolor: "#183D3D" }} />
          {/* {DrawerList.slice(1, 7).map((item) => {
            return (
              <ListItem
                component={Link}
                // activeClassName="Mui-selected"
                href={item.path}
                key={item.name}
                // onClick={() => navigate(`${item.path}`)}
                disablePadding
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      `${item.name}`
                      // t(`menu.${item.name}`, { ns: ["home"] })!
                    }
                    // primaryTypographyProps={{
                    //   fontSize: 14,
                    //   fontWeight: "medium",
                    // }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })} */}
        </List>
      </Stack>
      <Box
        style={{
          textDecoration: "none",
          width: "100%",
        }}
      >
        <Divider sx={{ bgcolor: "#183D3D" }} />
        <List
          dense
          style={{
            width: "100%",
          }}
        >
          {DrawerList.slice(-3, -1).map((item) => {
            return (
              <ListItem
                component={Link}
                href={item.path}
                key={item.name}
                disablePadding
              >
                <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      `${item.name}`
                      // t(`menu.${item.name}`, { ns: ["home"] })!
                    }
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
          {DrawerList.slice(-1).map((item) => {
            return (
              <ListItem key={item.name} disablePadding>
                <ListItemButton
                  onClick={() => handleLogout()}
                  sx={{ py: 0, minHeight: 32 }}
                >
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                  <ListItemText
                    primary={
                      `${item.name}`
                      // t(`menu.${item.name}`, { ns: ["home"] })!
                    }
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: "medium",
                    }}
                  ></ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Stack>
  );
  return (
    <Box sx={{ display: "flex" }}>
      {Token && (
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#ffffff",
          }}
        >
          <Toolbar>
            {Token ? (
              <Box sx={{ display: { sm: "none" } }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    mr: 2,
                    display: { sm: "none" },
                    color: "#183D3D",
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : null}
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <img alt="" src={medicalWISDOM} height="64px" />
            </Box>
            <Box sx={{ display: { sm: "none" } }}>
              <img src={"favicon.ico"} height="40" alt="" />
            </Box>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "flex-end",
                ml: "auto",
              }}
            >
              <Button
                aria-describedby={id}
                onClick={handleClick}
                style={{ float: "right" }}
              >
                <Grid container direction="row" spacing={1}>
                  <Grid>
                    <Avatar />
                  </Grid>
                  <Grid>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography variant="body2" noWrap>
                        {userDetails?.user?.first_name !== undefined &&
                        userDetails?.user?.last_name !== undefined
                          ? userDetails?.user?.first_name +
                            " " +
                            userDetails?.user?.last_name
                          : null}
                      </Typography>
                      <Typography
                        variant="caption"
                        noWrap
                        style={{
                          textTransform: "lowercase",
                        }}
                      >
                        {userDetails?.user?.email !== undefined
                          ? userDetails?.user?.email
                          : null}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItem component={Link} href="/userprofile">
                    <ListItemButton onClick={() => handleClose()}>
                      <ListItemText>
                        <Typography>User Profile</Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popover>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      {Token && (
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}

      <Router>
        <Box component="main" sx={{ flexGrow: 1 }}>
          {Token ? <Toolbar /> : null}
          {Token ? (
            <Box pt={1}>
              {loadingMarkup}
              <ComponentRouter />
            </Box>
          ) : (
            <ComponentRouter />
          )}
        </Box>
      </Router>
    </Box>
  );
}
