import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Url from "../../../Url";
import { LoginBody, GetPatientDetailsRes } from "./Model";
import { headers } from "../../../components/Utility";

type InitialState = {
  loading: boolean;
  patientLoginDetailsRes: GetPatientDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: 0;
};
const initialState: InitialState = {
  loading: false,
  patientLoginDetailsRes: {} as GetPatientDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};

export const patientLoginDetails = createAsyncThunk<
  GetPatientDetailsRes,
  LoginBody
>(
  "patientLoginDetails/patientLoginDetails",
  async (payload: LoginBody, { rejectWithValue }) => {
    let updatedHeaders = {
      ...headers,
      Authorization: `Bearer ${payload.access}`,
    };
    return await axios
      .get(Url.apiURL + "/patientapp/api/patient/patientLoginDetails", {
        headers: updatedHeaders,
      })
      .then((response) => {
        localStorage.setItem("loginDetails", JSON.stringify(response.data!)!);
        localStorage.setItem("firstname", response.data.user.first_name!);
        localStorage.setItem("lastname", response.data.user.last_name!);
        localStorage.setItem("email", response.data.user.email!);
        return response.data;
      })
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            "Something went wrong"
        )
      );
  }
);

const patientLoginDetailsSlice = createSlice({
  name: "patientLoginDetails",
  initialState,
  reducers: {
    updatePatientLoginDetailsResMsg: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
    },
    clearPatientLoginDetailsAPIRes: (state) => {
      state.successMsg = "";
      state.errorMsg = "";
      state.patientLoginDetailsRes = {} as GetPatientDetailsRes;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patientLoginDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      patientLoginDetails.fulfilled,
      (state, action: PayloadAction<GetPatientDetailsRes>) => {
        state.loading = false;
        state.patientLoginDetailsRes = action.payload;
        state.successMsg = "Participant login Successful";
        state.errorMsg = "";
      }
    );
    builder.addCase(patientLoginDetails.rejected, (state, action) => {
      state.loading = false;
      state.patientLoginDetailsRes = {} as GetPatientDetailsRes;
      state.successMsg = "";
      state.errorMsg = String(action.payload!);
    });
  },
});
export const {
  updatePatientLoginDetailsResMsg,
  clearPatientLoginDetailsAPIRes,
} = patientLoginDetailsSlice.actions;
export default patientLoginDetailsSlice.reducer;
