import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  Grid,
  Alert,
  Button,
  FormControl,
  Typography,
  TextField,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/forgotPassword/forgotPasswordSlice";
import { ForgotPasswordBody } from "../../redux/forgotPassword/Model";
import MWLoader from "../../components/MWLoader";
import { jwtAuthentication } from "../../redux/jwtPatientLogIn/jwtAuthenticationSlice";
import { JwtTokenAuthBody } from "../../redux/jwtPatientLogIn/Model";
import { patientLoginDetails } from "../../redux/jwtPatientLogIn/patientLoginDetails/patientLoginDetailsSlice";
import { LoginBody } from "../../redux/jwtPatientLogIn/patientLoginDetails/Model";
import { useAppSelector } from "../../redux/hooks";
import { AppDispatch } from "../../redux/store";

export default function Loginwithpasswordcomponent() {
  const dispatch = useDispatch<AppDispatch>();
  const apiAuthToken = useAppSelector((state) => state.apiAuthToken);
  const forgotPasswordRes = useAppSelector((state) => state.forgotPassword);
  const getPatientLoginDetailsValue = useAppSelector(
    (state) => state.getPatientLoginDetailsRes
  );
  const [email, setEmail] = useState("");
  const [errormail, setErrorMail] = useState("");
  const [errorpassword, setErrorPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [modalActive, setModalActive] = useState(false);
  const [supportEmail, setSupportEmail] = useState("");

  const [forgotpasswordRes, setForgotpasswordRes] = useState("");

  const [errorsupportemail, setErrorSupportEmail] = useState("");
  const errorSupportemail = errorsupportemail ? (
    <Typography>{errorsupportemail}</Typography>
  ) : (
    ""
  );
  // new code
  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPass] = React.useState<State>({
    password: "",
    showPassword: false,
  });
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPass({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPass({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const errorEmail = errormail ? <Typography>{errormail}</Typography> : "";

  const errorPassword = errorpassword ? (
    <Typography>{errorpassword}</Typography>
  ) : (
    ""
  );

  const closeBanner = () => {
    setError("");
  };

  const errorDiv = error ? (
    <Alert variant="standard" severity="error" onClose={() => closeBanner()}>
      <p>{error}</p>
    </Alert>
  ) : (
    ""
  );

  const loadingMarkup = isLoading ? <MWLoader /> : null;

  const body = {
    username: email.toLowerCase(),
    password: password.password,
  } as JwtTokenAuthBody;

  useEffect(() => {
    apiAuthToken?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [apiAuthToken]);

  //handle button click of login form Tab-1
  const handleLogin = () => {
    if (email === "" && password.password === "") {
      setErrorMail("Please enter email or phone number");
      setErrorPassword("Please enter password");
      return;
    } else if (email === "") {
      setErrorMail("Please enter email or phone number");
      setErrorPassword("");
      return;
    } else if (password.password === "") {
      setErrorMail("");
      setErrorPassword("Please enter password");
      return;
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setErrorMail("Please enter valid email id or phone number");
    } else {
      dispatch(jwtAuthentication(body));
    }
  };

  const [access, setToken] = useState("");

  useEffect(() => {
    if (apiAuthToken?.jwtAuthenticationRes?.access !== undefined) {
      setToken(apiAuthToken?.jwtAuthenticationRes?.access);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiAuthToken]);

  useEffect(() => {
    if (access !== "") {
      dispatch(
        patientLoginDetails({
          access: access,
        } as LoginBody)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  useEffect(() => {
    if (
      getPatientLoginDetailsValue?.patientLoginDetailsRes?.user !== undefined
    ) {
      window.location.reload();
    }
    if (getPatientLoginDetailsValue?.errorMsg !== "") {
      setError(getPatientLoginDetailsValue?.errorMsg);
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientLoginDetailsValue]);

  // ******* forgot password section modal **********

  const forgetPwd = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const supportEmailBody = {
    email: supportEmail,
  } as ForgotPasswordBody;

  const forgetPasswordApicall = () => {
    if (supportEmail === "") {
      setErrorSupportEmail("Please enter valid email id or phone number");
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        supportEmail
      ) === false
    ) {
      setErrorSupportEmail("Invalid Email");
    } else {
      dispatch(forgotPassword(supportEmailBody));
    }
  };

  useEffect(() => {
    if (forgotPasswordRes?.forgotPasswordRes?.message !== undefined) {
      setForgotpasswordRes(forgotPasswordRes?.forgotPasswordRes?.message);
    } else {
      setForgotpasswordRes(forgotPasswordRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPassword]);

  const forgetPwdPopup = () => {
    setSupportEmail("");
    setForgotpasswordRes("");
    setModalActive((modalActive) => !modalActive);
  };
  const modalMarkup = (
    <Dialog open={modalActive} onClose={forgetPwdPopup}>
      <DialogTitle>
        <Typography>Forgot Password?</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter your user account's verified email address and we will send you
          a password reset link.
        </DialogContentText>
        <TextField
          sx={{ my: 2 }}
          autoFocus
          margin="dense"
          size="small"
          fullWidth
          label={"Email or Phone No"}
          value={supportEmail}
          id="supportemail"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSupportEmail(event.target.value);
            setErrorSupportEmail("");
          }}
          helperText={
            errorSupportemail === ""
              ? "Please provide email or phone number"
              : errorSupportemail
          }
          error={errorSupportemail === "" ? false : true}
        />
        <Box>{forgotpasswordRes}</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={forgetPwdPopup}>Cancel</Button>
        <Button variant="contained" onClick={forgetPasswordApicall}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );

  const actualPageMarkup = (
    <Box onKeyDown={keyDownEvent}>
      {loadingMarkup}
      <TextField
        fullWidth
        sx={{ my: 2 }}
        size="small"
        value={email}
        error={errorEmail === "" ? false : true}
        label={"Email or Phone No"}
        placeholder={"Enter your registered email or Phone No"}
        id="emailFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
          setErrorMail("");
        }}
        helperText={
          errorEmail === ""
            ? "Please provide email or phone number"
            : errorEmail
        }
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel size="small" htmlFor="outlined-adornment-password">
          Enter your password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          size="small"
          error={errorPassword === "" ? false : true}
          placeholder={"Enter your password"}
          label={"Enter your password"}
          type={password.showPassword ? "text" : "Password"}
          value={password.password}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
              >
                {password.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            xs={12}
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                {errorPassword === "" ? "Please enter password" : errorPassword}
              </Box>
              <Button size="small" color="primary" onClick={forgetPwd}>
                Forgot Password?
              </Button>
            </Grid>
          </Grid>
        </FormHelperText>
      </FormControl>
      <Button
        sx={{ my: 2 }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleLogin}
      >
        Log In
      </Button>
      <div>{errorDiv}</div>
    </Box>
  );
  return (
    <div>
      {actualPageMarkup}
      {modalMarkup}
    </div>
  );
}
